import { Workbox } from "workbox-window";
let wb: Workbox | null = null;
if ("serviceWorker" in navigator) {

    wb = new Workbox(`serviceWorker.js`);


    // wb.addEventListener('installed', event => {
    //     console.log(`👾serviceWorker installed`)
    //
    // });
    //
    // wb.addEventListener('activated', event => {
    //     console.log(`👾serviceWorker activated, checking if update`)
    //     /**
    //      * We have the condition — event.isUpdate because we don’t want to show
    //      * this message on the very first service worker installation,
    //      * only on the updated
    //      */
    //     if (event.isUpdate) {
    //       //  window.location.reload();
    //         console.log(`👾serviceWorker activated, and updated`)
    //
    //     }
    // });
    wb.register().then((reg) => {
       // console.log("Registered service worker", reg);
    })
}
export default wb;